import { Typography, Row, Col, Space } from 'antd';

const { Title, Text } = Typography;

function DescriptionItem(props) {
  return (
    <Space align="center">
      <Title level={4}>
        {props.title}
      </Title>
      <Text type="secondary">
        {props.description}
      </Text>
    </Space>
  );
}

export default DescriptionItem;
